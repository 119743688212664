/**=====================
  2.23 Popover CSS Start
==========================**/
.popover-header {
  background-color: $dark-color;
  color: $white;
}

.popover-main {
  .btn-showcase {
    a {
      color: $white;
    }

    button {
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

/**=====================
  2.23 Popover CSS Ends
==========================**/