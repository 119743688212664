    /**=====================
    1.2 Typography CSS start
==========================**/
    //Custom css Start
    body {
        font-size: $body-font-size;
        overflow-x: hidden;
        font-family: $font-outfit, $font-serif;
        color: $theme-body-font-color;
        background-color: $main-body-color;
    }

    html,
    body {
        height: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    h1 {
        font-size: $heading_1;
        font-weight: 500;
        letter-spacing: $common_letter_spacing;
    }

    h2 {
        font-size: $heading_2;
        font-weight: 500;
        letter-spacing: $common_letter_spacing;
    }

    h3 {
        font-size: $heading_3;
        font-weight: 400;
        letter-spacing: $common_letter_spacing;
    }

    h4 {
        font-size: $heading_4;
        font-weight: 600;
        letter-spacing: $common_letter_spacing;
    }

    h5 {
        font-size: $heading_5;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: $common_letter_spacing;
    }

    h6 {
        font-size: $heading_6;
        font-weight: 500;
        line-height: 1;
        letter-spacing: $common_letter_spacing;
    }

    ul {
        padding-left: $ul-padding-left;
        list-style-type: none;
        margin-bottom: 0;
    }

    * a {
        color: var(--theme-deafult);
        text-decoration: none;
        letter-spacing: 1px;
    }

    .f-m-light {
        margin: 0;
    }

    a {
        font-size: 0.875rem;
        text-decoration: none;
        color: var(--theme-deafult);
        transition: 0.5s all;

        &:hover {
            color: var(--theme-deafult);
            transition: all 0.5s all;
        }

        &:focus-visible {
            outline: none;
        }
    }

    .btn {
        font-size: 14px;
    }

    *.btn:focus {
        box-shadow: $btn-focus-box-shadow  !important;
    }

    p {
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;
        letter-spacing: $paragraph-letter-spacing;
    }

    code {
        color: $code-tag-color  !important;
        border-radius: $code-tag-border-radious;
    }

    span {
        display: inline-block;
    }

    .d-flex {
        display: flex;
        align-items: flex-start;

        .flex-grow-1 {
            flex: 1;
        }
    }

    blockquote {
        border-left: $blockquote-border;
        padding: 18px;

        [dir="rtl"] & {
            border-left: unset;
            border-right: $blockquote-border;
        }
    }

    .figure {
        &.text-center {
            blockquote {
                border: none !important;
            }

        }

        &.text-end {
            blockquote {
                border-left: none;
                border-right: $blockquote-border;
                border-right-color: $warning-color;

                [dir="rtl"] & {
                    border-right: unset;
                    border-left: $blockquote-border  !important;
                    border-left-color: $warning-color  !important;
                }
            }
        }
    }

    .row {
        >div {
            position: relative;
        }
    }

    :focus {
        outline-color: $all-focus-outline-color;
    }

    .disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    svg {
        vertical-align: baseline;
    }

    input {
        &:focus {
            outline-color: $transparent-color;
        }
    }

    label {
        margin-bottom: .5rem;
    }

    .media-widgets {
        .flex-grow-1 {
            margin-bottom: 30px;
        }
    }

    //Custom Css end

    //Typography CSS start
    .typography {

        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            margin: 18px 0;
            margin-top: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        small {
            padding-left: $small-tag-padding;
            color: $small-tag-color;
        }
    }

    pre {
        background-color: $pre-tag-bg-color;
        padding: $pre-tag-padding;
    }

    // Typography CSS end

    .owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    background: lighten($primary-color, 20%);
                }

                &.active,
                &:hover {
                    span {
                        background: var(--theme-deafult);
                    }
                }
            }
        }

        .owl-nav {
            &.disabled {
                &+.owl-dots {
                    margin-bottom: -10px;
                    margin-top: 25px;
                }
            }
        }
    }

    .click2edit {
        &~.note-editor {
            &.note-frame {
                margin-bottom: 30px;
            }
        }
    }

    .note-editor {
        &.note-frame {
            border-color: $light-color;
        }

        &.fullscreen {
            .note-toolbar-wrapper {
                height: 0 !important;
            }
        }
    }

    .note-editor {
        &.note-frame {
            border-color: $light-color;
        }
    }

    // Modal page
    .modal {
        .modal-body {
            .card {
                box-shadow: none !important;
            }
        }
    }

    .modal-dialog {
        .modal-content {
            .modal-body {
                p {
                    a {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    // index page notify
    .notify-alert {
        .close {
            color: $white;

            [dir="rtl"] & {
                right: unset !important;
                left: 10px !important;
            }
        }

        &.alert-light {
            .close {
                color: $theme-body-font-color;
            }
        }
    }

    .alert-theme {
        button {
            top: 45px !important;
            right: 20px !important;
            color: var(--theme-deafult);

            [dir="rtl"] & {
                right: unset !important;
                left: 20px !important;
            }
        }

        span {
            +span {
                +span {
                    background-color: $white;
                    color: var(--theme-deafult);
                    border-radius: 4px;
                    box-shadow: 0 2px 14px -3px rgba($black, 0.2);
                }
            }
        }

        .close {
            color: var(--theme-deafult);
        }

        i {
            padding: 20px;
            padding-right: 0 !important;
            margin-right: 20px !important;
        }

        .progress {
            margin-top: -2px !important;
            height: 2px;
            width: 100%;

            .progress-bar {
                background-color: var(--theme-deafult);
            }
        }
    }

    .alert-copy {
        border: 1px dashed var(--theme-deafult);
        line-height: 1;
        display: inline-block;
        width: 300px;
        color: $white;
        background-color: var(--theme-deafult);

        .close {
            box-shadow: none;
            color: $white;
            line-height: 1.4;
            font-weight: 100;
            top: -15px;

            [dir="rtl"] & {
                right: unset !important;
                left: 10px !important;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .ace-editor {
        height: 400px;
        width: 100%;
    }

    .editor-toolbar {
        width: 100% !important;
        border-style: dashed;
        border-bottom: none;

        &.fullscreen {
            z-index: 100;
        }
    }



    .CodeMirror {
        top: 0 !important;
        border-style: dashed !important;
        border-top: none !important;
    }

    .CodeMirror-fullscreen {
        top: 50px !important;
    }

    // rangeslider
    .irs-from,
    .irs-to,
    .irs-single {
        background: var(--theme-deafult);

        &:after {
            border-top-color: var(--theme-deafult);
        }
    }

    .irs-line-mid,
    .irs-line-left,
    .irs-line-right,
    .irs-bar,
    .irs-bar-edge {
        background-color: var(--theme-deafult);
    }

    // sweetalert css
    .swal-button {
        background-color: var(--theme-deafult);

        &:active {
            background-color: darken($primary-color, 5%);
        }
    }

    .swal-button--cancel {
        background-color: #EFEFEE !important;
    }

    .clipboaard-container {
        h6 {
            line-height: 1.6;
            font-size: $paragraph-font-size;
        }
    }

    fieldset {

        .bootstrap-touchspin-postfix,
        .bootstrap-touchspin-prefix {
            border-radius: 0;
            padding: .4rem .75rem;
        }

        .input-group-lg {
            .touchspin {
                height: 50px;
            }
        }
    }

    .f1 .f1-steps .f1-step .f1-step-icon {
        padding: 12px;
    }

    .form-inline .form-group {
        display: flex;
    }

    .input-group-text {
        i {
            line-height: 1.5;
        }
    }

    // animated modal css
    .animated-modal {
        .form-select {
            display: inline-block;
            width: auto;
            vertical-align: middle;
        }
    }

    .card-wrapper {
        padding: 14px;

        dl {
            dt {
                font-weight: 500;
            }
        }
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    // * {
    //     scrollbar-width: auto;
    //     scrollbar-color: #f1e6f5 #ffffff;
    // }

    // table
    table {
        tbody {
            tr {
                vertical-align: middle;
            }
        }
    }

    // Common flex
    .common-flex {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
    }

    .typography-table {
        thead {
            tr {
                th {
                    font-size: 16px;
                }
            }
        }

        tbody {
            tr {
                td {
                    &:last-child {
                        min-width: 280px;
                    }

                    &:first-child {
                        min-width: 100px;
                    }
                }
            }
        }
    }

    /**=====================
    1.2 Typography CSS Ends
==========================**/