/**=====================
     2.11 Color CSS Start
==========================**/
.color-box {
  margin-bottom: -10px;

  button {
    margin-bottom: 10px;

    +button {
      margin-left: 5px;
    }
  }
}

.font-family-picker {
  div[id^=font-picker] {
    box-shadow: none !important;
    width: 100% !important;
  }

  .dropdown-button {
    background-color: white !important;
    border: dashed 1px rgba(106, 113, 133, 0.3);
    padding: 12px 10px !important;
    border-radius: 6px;
    height: auto !important;
  }

  div[id^=font-picker] ul {
    background-color: white !important;
    z-index: 4;
  }

  div[id^=font-picker].expanded ul {
    max-height: 216px !important;
  }
}

.light-font {
  color: $theme-body-sub-title-color;
}

/**=====================
     2.11 Color CSS Ends
==========================**/